import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { FileService } from '@app/services';

@Directive({
  selector: '[ngxFilesPreview]',
  standalone: true
})
export class FilesPreviewDirective {
  @Input() fileId;
  @Output() finishedLoading: EventEmitter<any> = new EventEmitter<boolean>();

  constructor(private fileService: FileService) {
  }

  @HostListener('click', ['$event'])
  clickHandler($event) {
    this.fileService.downloadFile(this.fileId)
      .subscribe((result) => {
        this.finishedLoading.emit(true);
        const fileName = (result.headers.get('content-disposition').split('filename=')[1]).replace(/"/g, '');
        const fileExt = fileName.split('.').pop();
        if (fileExt === 'pdf') {
          // Show as blob
          /*var newBlob = new Blob([result.body], {type: "application/pdf"});
          var blobURL = URL.createObjectURL(newBlob);
          const w = window.open(blobURL, '_blank');
          w.document.title = fileName;*/

         /* var downloadLink = document.createElement("a");
          downloadLink.href = blobURL;
          downloadLink.target = '_blank';
          downloadLink.download = fileName;

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);*/

          // Show as preview
          this.fileService.viewPdf(result.body, fileName);
        } else if (['jpg', 'jpeg', 'png', 'svg', 'webp'].includes(fileExt)) {
          this.fileService.viewImage(result.body, fileName);
        } else {
          this.fileService.downloadBlobFile(result.body, fileName);
        }
      }, (error) => {
        this.finishedLoading.emit(true);
        console.error(error);
      });
  }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Injector, OnInit } from '@angular/core';
import { User } from '@app/models';
import { PoFilesComponent } from '@app/modules/po-details/components/po-files/po-files.component';
import { AppConstants } from '@app/shared/constants/app.constants';
import { FileSizePipe } from '@app/shared/pipes';
import { Store } from '@ngrx/store';
import { TitleDividerComponent } from '../../title-divider/title-divider.component';
import { ContractBaseComponent } from '../contract-base.component';

@Component({
  standalone: true,
  selector: 'contract-documents-c',
  templateUrl: './contract-documents-c.component.html',
  styleUrls: ['./contract-documents-c.component.scss'],
  imports: [
    CommonModule,
    TitleDividerComponent,
    PoFilesComponent
  ]
})
export class ContractDocumentsCComponent extends ContractBaseComponent implements OnInit {
  addSubmittedDocumentFileEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  
  constructor(public injector: Injector, public store: Store<{ user: User }>) {
    super(injector, store)
  }


  ngOnInit(): void {
  }

  getCognitoFormUrl() {
    let entry = {
      ContractNumber: this.contract.customer_po_number,
      CreatedBy: this.currentUser.email
    }
    return 'https://www.cognitoforms.com/IONContracts/_2024CHPPINTERIMREPORT?entry=' + JSON.stringify(entry)
  }

  isCH() {
    return (this.customer && this.customer.name.includes('C+H'));
  }
}
